import React from "react"
import Layout from "../../components/Layout"

import { graphql } from "gatsby"
import Hero from "./components/hero"
import Sidebar from "./components/sidebar"
// import Pagination from "./components/pagination"
import HeroPost from "./components/heroPost"
import Posts from "./components/posts"

export const query = graphql`
  query {
    allContentfulBlogPost(sort: { fields: publishedDate, order: DESC }) {
      edges {
        node {
          title
          category
          slug
          publishedDate(fromNow: true)
          thumbnail {
            title
            file {
              url
            }
          }
        }
      }
    }
  }
`

const Blog = ({ data }) => {
  const {
    allContentfulBlogPost: { edges },
  } = data
  let categories = []
  const recents = []

  let posts = edges.filter((b, i) => {
    if (b.node.slug !== "safe") {
      categories.push({ category: b.node.category, link: "/blog" })
      edges.length - i < 4 && recents.push(b.node)
      return true
    }
    return false
  })
  const heroPost = [...posts].shift()

  return (
    <Layout>
      {/* <Hero /> */}
      <div className="main-container">
        <Hero />
        <section className="bg-secondary">
          <div className="container">
            <div className="row">
              <div className="col-md-9 col-md-push-3 mb-xs-24">
                <HeroPost post={heroPost.node} />
                <hr />
                {edges.length && <Posts posts={posts} />}
                {/* <Pagination /> */}
              </div>
              {/* <!--end of nine col--> */}
              {/* sideBar */}
              <Sidebar categories={categories} recents={recents} />
              {/* <!--end of sidebar--> */}
            </div>
            {/* <!--end of container row--> */}
          </div>
          {/* <!--end of container--> */}
        </section>
      </div>
    </Layout>
  )
}

export default Blog
